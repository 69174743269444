<template>
  <div class="container ctm-block" v-if="ticketData">
    <b-alert v-model="ticketNotUsed" variant="success">¡Entrada validada con éxito!</b-alert>
    <b-alert v-model="ticketUsed" variant="danger">Entrada no válida. <br>Esta entrada ya ha sido validada anteriormente.</b-alert>
    <p>Información de la entrada:</p>
    <div v-if="ticketData.ticketNumber && ticketData.ticketNumber > 0">
      <p>
        <strong>Nº entrada:</strong> {{ ticketData.ticketNumber }}
      </p>
    </div>
    <div v-else>
      <p>
        <strong>Nombre:</strong> {{ ticketData.user.name + ' ' + ticketData.user.surname }}
      </p>
      <p>
        <strong>Email:</strong> {{ ticketData.user.email }}
      </p>
      <p>
        <strong>DNI:</strong> {{ ticketData.user.dni }}
      </p>
    </div>
    <!-- <p>
      <strong>Fecha de compra:</strong> {{ ticketData.date }}
    </p> -->
    <!-- <b-button class="mr-2" variant="danger" @click="revertValidation">No validar</b-button> -->
    <b-button variant="success" @click="finishValidation">Validar otra entrada</b-button>
  </div>
  <div v-else class="text-center" :class="{ 'ctm-block': manualValidation }">
    <div v-if="!manualValidation">
      <qrcode-stream v-if="!manualValidation" @decode="onDecode" @init="onInit" style="height: 100vh"/>
      <b-button class="mt-4" variant="info" @click="openManualValidation">Validar entrada manualmente</b-button>
    </div>
    <div style="padding-top: 3rem" v-else>
      <select class="form-control" id="event" name="event" v-model="selectedEventId" required v-if="events">
          <option v-for="event in events" :value="event.id" :key="event.id">{{ event.name }}</option>
      </select>

      <input class="form-control mt-4" type="text" v-model="ticketNumber" placeholder="Introduce el número de entrada">

      <b-button class="mt-4" variant="success" @click="validateTicket">Validar</b-button>
      <b-button class="mt-4" variant="info" @click="() => { manualValidation = false }">Volver a validación por QR</b-button>
    </div>
    
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream
  },
  data () {
    return {
      ticketData: null,
      events: [],
      selectedEventId: null,
      manualValidation: false,
      ticketNumber: null
    }
  },
  computed: {
    ticketUsed () {
      return this.ticketData.alreadyUsed
    },
    ticketNotUsed () {
      return !this.ticketData.alreadyUsed
    }
  },
  methods: {
    onDecode (data) {
      // If localhost
      if (window.location.href.includes('localhost')) {
        data = data.replace('api.partyrevive.es', 'localhost:44388')
      }
      this.axios.get(data, {
        headers: {
          Authorization: 'Bearer ' + this.$cookies.get("token")
        }
      }).then(response => {
        this.ticketData = response.data
      })
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
    finishValidation () {
      this.ticketData = null
    },
    revertValidation () {
      this.axios.delete('/admin/tickets/' + this.ticketData.ticketId).then(response => {
        if (response) {
          alert('La validación se ha revertido. La entrada puede volver a ser validada de nuevo.')
          this.ticketData = null
        }
      })
    },
    openManualValidation () {
      this.manualValidation = true
    },
    validateTicket () {
      this.axios.post('/admin/qr', {
          ticketNumber: this.ticketNumber,
          eventId: this.selectedEventId
        }).then(response => {
        this.ticketData = response.data
      })
    },
  },
  mounted () {
    if (this.$cookies.get("token")) {
      this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get("token")
      this.axios.get('/events', { params: { lists: false }}).then(response => {
        // Order by event date
        this.events = response.data.sort((a, b) => {
          return new Date(b.createD_AT) - new Date(a.createD_AT)
        })
        this.selectedEventId = this.events[0].id
        this.manualValidation = false
      })
    } else {
      this.$router.push('/login?returnUrl=' + this.$route.path)
    }
  }
}
</script>

<style>

.ctm-block {
  margin-top: 5rem;
  background-color: #000000b8;
  padding: 3rem;
}

</style>